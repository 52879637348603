import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'sponsor',
    loadChildren: () => import('./pages/sponsor/sponsor.module').then( m => m.SponsorPageModule)
  },
  {
    path: 'program',
    loadChildren: () => import('./pages/program/program.module').then( m => m.ProgramPageModule)
  },
  {
    path: 'program-details/:slug',
    loadChildren: () => import('./pages/program-details/program-details.module').then( m => m.ProgramDetailsPageModule)
  },
  {
    path: 'gallery',
    loadChildren: () => import('./pages/gallery/gallery.module').then( m => m.GalleryPageModule)
  },
  {
    path: 'magazine-list',
    loadChildren: () => import('./pages/magazine-list/magazine-list.module').then( m => m.MagazineListPageModule)
  },
  {
    path: 'magazine-details/:id',
    loadChildren: () => import('./pages/magazine-details/magazine-details.module').then( m => m.MagazineDetailsPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
